import React from "react";
import { useState, useEffect } from "react";
import Header from "./Header.scss";
import { Link,NavLink } from "react-router-dom";
const App = () => {
  const greeting = "Hello Function Component!";
  const [open, isOpen] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  // console.log(open);

  //   React.useEffect(() => {
  //     function handleResize() {

  //       while(window.innerWidth > 900){
  //         isOpen((open) => false)
  //         console.log(open);
  //       }
  // }

  //     window.addEventListener('resize', handleResize)
  //   })

  return (
    <div className="header_container">
      <div className={open === true ? " menuToggle" : "hide_menuToggle"}>
        <div className="menu-container">
          <div className={open === true ? "menu-items" : "hide_menuitems"}>
            <div className="menu-item">
              <NavLink className="menu-item__inner no_decoration" activeClassName="selected" to="/home">HOME</NavLink>
            </div>
            <div className="menu-item">
              <NavLink className="menu-item__inner no_decoration" activeClassName="selected" to="/about">
                ABOUT
              </NavLink>
            </div>
            <div className="menu-item">
              <NavLink className="menu-item__inner no_decoration" activeClassName="selected" to="/projects">
                PROJECTS
              </NavLink>
            </div>

            {/* <div className="menu-item">
            <NavLink className="menu-item__inner no_decoration" activeClassName="selected" to="/services">
              PACKAGES
              </NavLink>
            </div> */}
            
            <div className="menu-item">
              <Link className="menu-item__inner no_decoration" to="/contact">
                CONTACT
              </Link>
            </div>
            <button
              onClick={() => isOpen((open) => !open)}
              className={open === true ? " close-button" : "hide_close-button"}
            ></button>
          </div>
        </div>
      </div>
      <div
        href="/"
        className={open === true ? " hamburger_active icon" : "hamburger icon"}
        onClick={() => isOpen((open) => !open)}>
        <i className="fa fa-bars"></i>
      </div>
      <header className="header">
        <div>
          <div className="logo-wrap">
            {/* <div className="logo-image">
              <img className="logo" src={require("../../fonts/logo.png")} />
              <h6>MERILYNANASTASIOU</h6>
            </div> */}
            <div className="logo-image">
              <img className="logo" src={require("../../fonts/MY_LOGO.png")} />
            </div>
            <div className="button_contant">
              <NavLink
              activeClassName="selected"
                className="button_contant__inner no_decoration"
                to="/contact"
              >
                CONTACT
              </NavLink>
            </div>

            {/* <div className="hamburger">
                <a href="/" class="icon">
                      <i class="fa fa-bars"></i>
                </a>
            </div> */}
          </div>
        </div>
        <nav>
          <ul className="nav-bar">
            <li className="_3myBa">
              <NavLink to="/home" activeClassName="selected" className="nav-bar__links" >
                HOME
              </NavLink>
            </li>
            <li className="_3myBb">
              <NavLink className="menu-item__inner no_decoration" activeClassName="selected" to="/about">
                ABOUT
              </NavLink>
            </li>
            <li className="_3myBb">
              <NavLink className="menu-item__inner no_decoration" activeClassName="selected" to="/projects">
                PROJECTS
              </NavLink>
            </li>
            {/* <li className="_3myBb">
              <NavLink className="menu-item__inner no_decoration" activeClassName="selected" to="/services">
                PACKAGES
              </NavLink>
            </li> */}
            {/* <li className="_3myBb">
              <a>CONTACT US</a>
              
            </li> */}
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default App;
