import React from "react";
import { BrowserRouter as Router, Link, Route, Switch,Redirect } from "react-router-dom";
import logo from "./logo.svg";
import "./App.scss";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Contact from "./components/contact/Contact";
import About from "./components/about/About";
import Services from "./components/services/Services";
import Projects from "./components/projects/Projects";
import Gallery from "./components/gallery/Gallery";
import Commercial from "./components/projects/commercial/Commercial";
import Residental from "./components/projects/residental/Residental";
import ProgressiveImage from "./test/ProgressiveImage";
import PhotosTest from "./photo_test";
import { ias } from "./components/gallery/photos";
import ScrollToTop from "./components/ScrollToTop";
import CustomHttp from "./components/CustomHttp/CustomHttp";
import { PageNotFound } from "./PageNotFound";
class App extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {imageIsReady: false};
    

  }
  componentDidMount() {
    // document.addEventListener('contextmenu', (e) => {
    //   e.preventDefault();
    // });
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }
  render() {
    return (
      <div className="App">
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/test" component={CustomHttp} />
            <Route exact path="/">
              <Redirect to="/home" /> : <Home />
            </Route>
            <Route path="/home" component={Home} />
            <Route path="/contact" component={Contact} />
            <Route path="/about" component={About} />
            {/* <Route path="/services" component={Services} /> */}
            <Route path="/projects" component={Projects} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/commercial" component={Commercial} />
            <Route path="/residental" component={Residental} />
            <Route path="*" component={PageNotFound} />
            {/* <Route path="/ProgressiveImage" component={ProgressiveImage} /> */}

            {/* <Route path="/test" component={PhotosTest} /> */}

            {/* <Route component={ErrorComponent}/> */}
          </Switch>
        </Router>

        {/* <ProgressiveImage src={ias} placeholder= {'https://picsum.photos/200'}/> */}
        {/* <Footer /> */}
      </div>
    );
  }
}

export default App;
