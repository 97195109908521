import React, { useState, useEffect, useRef } from "react";
import { useFullScreen } from "react-hooks-full-screen";
import { useLocation } from "react-router-dom";
import Header from "../header/Header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Footer from "../footer/Footer";
import { ArrowRightShort, ArrowLeftShort } from "react-bootstrap-icons";
import Images from "../img/Image";
import {
  jersey_street,
  margarita,
  ias,
  bay_view,
  HOUSE_INTERIOR_DESIGN_1,
  HOUSE_INTERIOR_DESIGN_2,
  HOUSE_INTERIOR_DESIGN_3,
  HOUSE_INTERIOR_DESIGN_5,
} from "./photos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Element, Events, animateScroll, scroller } from "react-scroll";
import exitIcon from "../../assets/icons/circle-xmark-regular.svg";

import "./Gallery.scss";
const Gallery = () => {
  const location = useLocation();
  const [slideNumber, setslideNumber] = useState(0);
  const [isFullScreen, setFullScreen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [imageKey, setImageKey] = useState(0);
  const [showFullScreen, setShowFullScreen] = useState(false);
  useFullScreen("modal_image", showFullScreen);

  useEffect(() => {}, []);

  const hendleOpenModal = (index) => {
    setslideNumber(index);
    setOpenModal(true);
    setImageKey(index);
  };

  const hendleCloseModal = () => {
    setOpenModal(false);
    setFullScreen(false);
  };

  let checkModalImageURL = () => {
    let str;
    if (location.state.project === "MARGARITA") {
      str = margarita;
    } else if (location.state.project === "jersey_street") {
      str = jersey_street;
    } else if (location.state.project === "BAY_VIEW") {
      str = bay_view;
    } else if (location.state.project === "IAS") {
      str = ias;
    } else if (location.state.project === "HOUSE_INTERIOR_DESIGN_1") {
      str = HOUSE_INTERIOR_DESIGN_1;
    } else if (location.state.project === "HOUSE_INTERIOR_DESIGN_2") {
      str = HOUSE_INTERIOR_DESIGN_2;
    } else if (location.state.project === "HOUSE_INTERIOR_DESIGN_3") {
      str = HOUSE_INTERIOR_DESIGN_3;
    }else if (location.state.project === "HOUSE_INTERIOR_DESIGN_5") {
      str = HOUSE_INTERIOR_DESIGN_5;
    }


    
    return str;
  };

  let checkImageURL = () => {
    let str;
    if (location.state.project === "MARGARITA") {
      str = margarita;
    } else if (location.state.project === "jersey_street") {
      // str = jersey_street;
      if (openModal)
        str = { photos: jersey_street.photos, data: jersey_street };
      else str = { photos: jersey_street.photos_small, data: jersey_street };
    } else if (location.state.project === "BAY_VIEW") {
      str = bay_view;
    } else if (location.state.project === "IAS") {
      // str = ias;
      if (openModal) str = { photos: ias.photos, data: ias };
      else str = { photos: ias.photos_small, data: ias };
    } else if (location.state.project === "HOUSE_INTERIOR_DESIGN_1") {
      str = HOUSE_INTERIOR_DESIGN_1;
      // if(openModal)  str = HOUSE_INTERIOR_DESIGN_1.photos;
      // else str = HOUSE_INTERIOR_DESIGN_1.photos_small;
      if (openModal)
        str = {
          photos: HOUSE_INTERIOR_DESIGN_1.photos,
          data: HOUSE_INTERIOR_DESIGN_1,
        };
      else
        str = {
          photos: HOUSE_INTERIOR_DESIGN_1.photos_small,
          data: HOUSE_INTERIOR_DESIGN_1,
        };
    } else if (location.state.project === "HOUSE_INTERIOR_DESIGN_2") {
      str = HOUSE_INTERIOR_DESIGN_2;
      // if(openModal)  str = HOUSE_INTERIOR_DESIGN_2.photos;
      // else str = HOUSE_INTERIOR_DESIGN_2.photos_small;
      if (openModal)
        str = {
          photos: HOUSE_INTERIOR_DESIGN_2.photos,
          data: HOUSE_INTERIOR_DESIGN_2,
        };
      else
        str = {
          photos: HOUSE_INTERIOR_DESIGN_2.photos_small,
          data: HOUSE_INTERIOR_DESIGN_2,
        };
    } else if (location.state.project === "HOUSE_INTERIOR_DESIGN_3") {
      // str = HOUSE_INTERIOR_DESIGN_3;
      if (openModal)
        str = {
          photos: HOUSE_INTERIOR_DESIGN_3.photos,
          data: HOUSE_INTERIOR_DESIGN_3,
        };
      else
        str = {
          photos: HOUSE_INTERIOR_DESIGN_3.photos_small,
          data: HOUSE_INTERIOR_DESIGN_3,
        };
    } else if (location.state.project === "HOUSE_INTERIOR_DESIGN_5") {
      // str = HOUSE_INTERIOR_DESIGN_5;
      if (openModal)
        str = {
          photos: HOUSE_INTERIOR_DESIGN_5.photos,
          data: HOUSE_INTERIOR_DESIGN_5,
        };
      else
        str = {
          photos: HOUSE_INTERIOR_DESIGN_5.photos_small,
          data: HOUSE_INTERIOR_DESIGN_5,
        };
    }
    return str;
  };

  let CheckImageURL = checkImageURL();
  let ImagesArray;

  ImagesArray = CheckImageURL["photos"].map((res, index) => {
    return (
      <div key={index} className={res["class"]}>
        <div className="gallery-item">
          <div className="image">
            {/* <LazyLoadImage
              loading="lazy"
              src={res["name"]}
              onClick={() => hendleOpenModal(index)}
            /> */}

            <Images
              className="image"
              click={() => hendleOpenModal(index)}
              highQualitySrc={res["name"]}
              lowQualitySrc={res["lowImage"]}
            />
          </div>
        </div>
      </div>
    );
  });

  useEffect(() => {
    const keyDownHandler = (event) => {
      // console.log('User pressed: ', event.keyCode);

      if (event.keyCode == 27) {
        // console.log("User pressed: ", event.keyCode);
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    document.addEventListener("webkitfullscreenchange", keyDownHandler);
    document.addEventListener("mozfullscreenchange", keyDownHandler);
    document.addEventListener("fullscreenchange", keyDownHandler);
    document.addEventListener("MSFullscreenChange", keyDownHandler);

    if (document.addEventListener) {
    }

    if (openModal) {
      document.body.style.overflow = "hidden";
      //   window.scrollTo({
      //     top: 0,
      //     behavior: 'smooth',
      // });
    } else {
      document.body.style.overflow = "visible";
    }

    // setFullScreen(showFullScreen);use this if onclick  setShowFullScreen onlick is enable
  });

  const handleSlideRight = () => {
    if (slideNumber < ImagesArray.length - 1) {
      setslideNumber((preNumber) => preNumber + 1);
      setImageKey((preNumber) => preNumber + 1);
    }
    return;
  };

  const handleSlideLeft = () => {
    if (slideNumber > 0) {
      setslideNumber((preNumber) => preNumber - 1);
      setImageKey((preNumber) => preNumber - 1);
    }
    return;
  };
  let fullScreen = () => {
    var full_screen_element = document.fullscreenElement;
    let getImage = document.getElementById("modal_image");
    // console.log(getImage);
    if (getImage.requestFullscreen) {
      getImage.requestFullscreen();
    }
  };

  let modal_wrapper__modal_btn = {
    with: "20px",
    height: "20px",
  };
  // let StyleContainer ={
  //   margin:' 0 auto',
  //   padding: '20px 0px',
  //   display: 'grid',
  //   gridTemplateColumns: 'repeat(4,1fr)',
  //   gridAutoRows: '100px 300px',
  //   gridGap: '10px',
  //   gridAutoFlow: 'dense',
  // }

  let StyleContainer = ["container"];

  if (location.state.project === "jersey_street") {
    StyleContainer.push("column4-1");
  }
  if (location.state.project === "MARGARITA") {
    StyleContainer.push("column3-1");
  }
  if (location.state.project === "IAS") {
    StyleContainer.push("column4-1");
  }
  if (location.state.project === "HOUSE_INTERIOR_DESIGN_5") {
    StyleContainer.push("column4-1");
  }

  return (
    <div className="Gallery">
      {/* Modal */}
      <div className={`modal_wrapper ${openModal ? "isOpen" : "isClose"}`}>
        <div className="modal"></div>
        <div className="modal_details">
          <div
            className="modal_image_wrapper"
            style={{
              width: `${isFullScreen ? "100%" : "80rem"}`,
              height: `${isFullScreen ? "100%" : "40rem"}`,
            }} >
            <div
              style={{
                backgroundImage: `url(${CheckImageURL["photos"][imageKey]["name"]})`,
                // pointerEvents: `${isFullScreen ? 'none' : 'auto'}`,
              }}
              id="modal_image"
              className="modal_image"
            >
              <FontAwesomeIcon
                icon={faExpand}
                onClick={() => setFullScreen(!isFullScreen)}
                className="expand_button filter-color "
              />
              {/* <FontAwesomeIcon
                icon={faCircleChevronLeft}
                className={`modal__left_arrow filter-color ${
                  imageKey === 0 ? "hideArrow" : "showArrow"
                }`}
                onClick={() => handleSlideLeft()}
              /> */}
              <ArrowLeftShort
                // icon={faCircleChevronLeft}
                className={`modal__left_arrow filter-color ${
                  imageKey === 0 ? "hideArrow" : "showArrow"
                }`}
                onClick={() => handleSlideLeft()}
              />
              <ArrowRightShort
                className={`modal__right_arrow filter-color ${
                  slideNumber == ImagesArray.length - 1
                    ? "hideArrow"
                    : "showArrow"
                }`}
                onClick={() => handleSlideRight()}
              />
              ;
              {/* <FontAwesomeIcon
                icon={faCircleChevronRight}
                className={`modal__right_arrow filter-color ${
                  slideNumber == Image.length - 1 ? "hideArrow" : "showArrow"
                }`}
                onClick={() => handleSlideRight()}
              /> */}
              {/* Start of close btn */}
              <div className="close" onClick={() => hendleCloseModal()}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <svg viewBox="0 0 36 36" className="circle">
                  <path
                    strokeDasharray="100, 100"
                    d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                </svg>
              </div>
            </div>
            {/* End of close btn */}
            {/* <img
                src={CheckImageURL["photos"][0]}
                class="modal_image"
                alt=""
              /> */}
          </div>
        </div>
      </div>

      {/* End of Modal */}

      <Header />
      <div className="Gallery__photos_header">
        <div className="Gallery__photos_wrapper">
          <h1 className="Gallery__photos_title">{CheckImageURL.data.title}</h1>
          <h1 className="Gallery__photos_location">
            {CheckImageURL.data.location}
          </h1>
        </div>
      </div>

      <h1 className="Gallery__photos_description">
        {CheckImageURL.data.description}
      </h1>

      <div className={StyleContainer.join(" ")}>
        {ImagesArray.map((img, index) => {
          return img;
        })}
      </div>
      <Footer path={location.state.project} />
    </div>
  );
};

export default Gallery;
{
}
