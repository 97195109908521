import React, { useState, useEffect } from "react";
import "./Image.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Images = ({ click, highQualitySrc,lowQualitySrc }) => {

  const [src, setSrc] = useState(lowQualitySrc)
  useEffect(() => {

    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {

      setSrc(highQualitySrc);

    };

  }, [lowQualitySrc, highQualitySrc]);

  return    <LazyLoadImage
        
  loading="lazy"
  src={src}
  onClick={click}
  style={{
    filter: src ===lowQualitySrc  ? "blur(20px)" : "none",
    transition: src ===lowQualitySrc ? "none" : "filter 1.3s ease-out",
    cursor: "pointer" 
    
  }}
/> 
  
  
  
  // <img 
  // className="lazyImage"
  // loading="lazy"
  //       src={src} 
  //       style={{
  //         filter: src ===lowQualitySrc  ? "blur(20px)" : "none",
  //         transition: src ===lowQualitySrc ? "none" : "filter 1.3s ease-out",
  //         cursor: "pointer" 
  //       }}
  //       onClick={click}
  //       />;

  


       
};

export default Images;