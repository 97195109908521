import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../header/Header";
import Images from "../../img/Image";
import Footer from "../../footer/Footer";


import one from "../../../assets/21 JERSEY STREET/1.jpg";
import HOUSE_INTERIOR_DESIGN_1 from "../../../assets/HOUSE INTERIOR DESIGN 1/K1.jpg";
import HOUSE_INTERIOR_DESIGN_2 from "../../../assets/HOUSE INTERIOR DESIGN 2/1.jpg";
import HOUSE_INTERIOR_DESIGN_3 from "../../../assets/HOUSE INTERIOR DESIGN 3/1.png";
import HOUSE_INTERIOR_DESIGN_5 from "../../../assets/HOUSE INTERIOR DESIGN 5/1.png";
import  img1 from "../../../assets/small_img/1.jpg"
import  img2 from "../../../assets/small_img/2.jpg"
import  img3 from "../../../assets/small_img/3.jpg"
import  img4 from "../../../assets/small_img/4.jpg"
import  img5 from "../../../assets/small_img/5.jpg"
import "./Commercial.scss";

const Commercial = () => {
  const history = useHistory();
  const photos = [
    {
      title: "CONDO INTERIOR DESIGN",
      project: "jersey_street",
      lowImage:img1,
      name: one,
    },
    {
      title: "HOUSE INTERIOR DESIGN",
      project: "HOUSE_INTERIOR_DESIGN_1",
      lowImage:img2,
      name: HOUSE_INTERIOR_DESIGN_1,
    },
    {
      title: "HOUSE INTERIOR DESIGN",
      project: "HOUSE_INTERIOR_DESIGN_2",
      lowImage:img3,
      name: HOUSE_INTERIOR_DESIGN_2,
    },
    {
      title: "HOUSE INTERIOR DESIGN",
      project: "HOUSE_INTERIOR_DESIGN_3",
      lowImage:img4,
      name: HOUSE_INTERIOR_DESIGN_3,
    },
  
  
    {
      title: "HOUSE INTERIOR DESIGN",
      project: "HOUSE_INTERIOR_DESIGN_5",
      lowImage:img5,
      name: HOUSE_INTERIOR_DESIGN_5,
    },
  
    

 
  ];

  function pushToGellery(e) {
    history.push({
      pathname: "/gallery",
      state: e,
    });
  }
  return (
    <div className="Commercial">
      <Header />
      <div className="Commercial__main">
        <div className="Commercial__flex">
          {photos.map((res, index) => {
            return (
              <div className="Commercial__photos_wrapper">
                {/* <span className="Commercial__title">{res.title}</span> */}
                <Images
                  click={(e) => pushToGellery(res)}
                  highQualitySrc={res.name}
                  key={index}
                  lowQualitySrc = {res.lowImage}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Commercial;
