import React, { useState, useEffect } from "react";
import { Route, Link, Routes, useLocation } from "react-router-dom";
import "./Footer.scss";

const Footer = (props) => {
  let style
  const [width, setWidth] = useState(1150);
  const [path, setPath] = useState("");
  const location = useLocation();

  useEffect(() => {
    setPath(props.path);
    
    let handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  });
  
  if (path === "MARGARITA") {
    
    style = {
      position: window.innerWidth <= 1500 ? "relative" : "relative",
    };
  }
  return (
    <div>
      <footer>
        <div className="footer_container" style={style}>
          <div className="social_wrapper">
            <a href="https://www.facebook.com/ginger3dstudio"  target="_blank" className="fa fa-facebook"></a>
            <a href="https://www.instagram.com/ginger.3d.visualization.studio/" target="_blank" className="fa fa-instagram"></a>
            <a href="https://www.behance.net/gingervisuali" target="_blank" className="fa fa-behance"></a>

          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
