import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./About.scss";
const About = () => {
  return (
    <div className="About">
      <Header />
      <div className="wrapper">
        <section>
          <main>
            <div className="flex-wrapper">
              {/* Right side */}
              <div className="right_img">
                <h2>About us</h2>
                <div className="body_wrapper">
                  {/* Left side */}
                  <div className="left_img">
                    <img
                      src={require("../../MY_LOGO.png")}
                      // src={require("../../assets/carousel/K1.jpg")}
                      width="220px"
                      height="220px"
                      className="section-background__img "
                      id="section-background__img"
                      alt=""
                    />
                  </div>
                  <p>
                    Ginger Studio offers their services for residential and
                    commercial projects. We work closely with our clients to
                    offer them their desired result and we always aim to create
                    spaces that provide joy, comfort and beauty to its users.
                    <br />
                    Our services accommodate both early design stages of a new
                    building development, or renovations of an existing
                    building. 
                    <br />
                    Our knowledge of materials and design techniques
                    as well as our strong connection to associates that offer
                    those, helps us bring forward the best result for your
                    needs. Our services may be included in all stages of design,
                    from early on working with the architects to selecting or
                    even designing your furniture pieces as well as consulting
                    and supervising till the end of the project.
                    <br />
                    Through our 3D Visualization you will be able to see your
                    project come to life and experience the atmosphere before
                    committing to any final decisions.
                  </p>
                </div>
              </div>
            </div>
          </main>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default About;
