import React, { Component } from "react";
import PropTypes from "prop-types";
import { height } from "@mui/system";

class ProgressiveImage extends Component {
  static defaultProps = {
    alt: "",
  };

  constructor(props) {
    super(props);
 
    // initially set loading to true and current src of image to placeholder image
    this.state = {
      loading: true,
      currentSrc: props.src,
    };
  }

  componentDidMount() {
    const { src } = this.props;
   let imageAdded = []
    // start loading original image
    const imageToLoad = new Image();


  
    src['photos'].forEach((item, index) => {
      imageToLoad.src = src['photos'][index]['name'];
    
  
  });


  imageToLoad.onload = () =>{
    // this.setState(prevState => ({
    //   imageAdded: [...prevState.imageAdded, src['photos'][index]['name']]
    // }))
    // imageAdded.push(src['photos'][index]['name'])

  // When image is loaded replace the image's src and set loading to false
  this.setState({ currentSrc: src, loading: false });

  }


  }

  render() {
    const { currentSrc, loading } = this.state;
    const { alt,placeholder } = this.props;
    const Style = { height: "100%" };
    Style.opacity = loading ? "0.5" : "1";
    Style.transition = "opacity .15s linear";


    function ImageSrc() {
     return( currentSrc["photos"].map((res, index) => {
        return (
          <div>
            <img alt="test"  className="ProgressiveImage" style={Style} src={res["name"]} />
          </div>
        );
      })
     )
    }//End of ImageSrc


    function AltSrc(){
      return( currentSrc["photos"].map((res, index) => {
        return (
          <div>
            <img className="ProgressiveImage" style={Style} src={currentSrc} />
          </div>
        );
      })
     )
    }

    function RenderImage() {

      if (typeof currentSrc  === 'object') return <ImageSrc />;
      return <AltSrc />
    }
    return (
      <>
        <RenderImage />
      </>

      // <img
      //   src={currentSrc}
      //   className="ProgressiveImage"
      //   style={Style}
      //   alt={alt}
      // />
    );
  }
}

ProgressiveImage.propTypes = {
  //   src: PropTypes.string.isRequired,
  //   placeholder: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default ProgressiveImage;
