import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import highQualitySrc_1 from"../../assets/primary_background-min.jpg"
import lowQualitySrc_1 from  "../../assets/small_img/primary_background-min.jpg"
import highQualitySrc_2 from"../../assets/carousel/../../assets/MARGARITA/20190727_133757.jpg"
import lowQualitySrc_2 from  "../../assets/small_img/20190727_133757.jpg"

import "./Projects.scss";
const Projects = () => {

  const [src_1, setSrc_1] = React.useState(lowQualitySrc_1)
  const [src_2, setSrc_2] = React.useState(lowQualitySrc_2)


  useEffect(() => {
    setSrc_1(lowQualitySrc_1);
    const img = new Image();
    img.src = highQualitySrc_1;
    img.onload = () => {
      setSrc_1(highQualitySrc_1);

    };
  }, [lowQualitySrc_1, highQualitySrc_1]);


  useEffect(() => {
    setSrc_2(lowQualitySrc_2);
    const img = new Image();
    img.src = highQualitySrc_2;
    img.onload = () => {
      setSrc_2(highQualitySrc_2);

    };
  }, [lowQualitySrc_2, highQualitySrc_2]);

  return (
    <div className="Projects">
      <Header />
      <div className="Projects__flex">

        <Link className="Projects__img after_left" to="/commercial">
          <img
            className="section-background__img "
            id="section-background__img"
            // src={require("../../assets/primary_background-min.jpg")}
            src={src_1}
            alt=""
            style={{
              filter: src_1 ===lowQualitySrc_1  ? "blur(20px)" : "none",
              transition: src_1 ===lowQualitySrc_1 ? "none" : "filter 1.3s ease-out"
            }}
          />
          {/* <span className="title">
            <span className="no_decoration" >
              3D VISUALIZATION
            </span>
          </span> */}
        </Link>

        <Link className="Projects__img after_right" to="/residental">
          <img
            className="section-background__img "
            id="section-background__img"
            // src={require("../../assets/MARGARITA/20190727_133757.jpg")}
            src={src_2}
            alt=""
            style={{
              filter: src_1 ===lowQualitySrc_1  ? "blur(20px)" : "none",
              transition: src_1 ===lowQualitySrc_1 ? "none" : "filter 1.3s ease-out"
            }}
          />
          {/* <span className="title">
            <span className="no_decoration" style={{color: "white"}}>
              RESIDENTIAL
            </span>
          </span> */}
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Projects;
