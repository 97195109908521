import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../header/Header";
import Image from "../../img/Image";
import Footer from "../../footer/Footer";
import one from "../../../assets/21 JERSEY STREET/1.jpg";
import margarita_20190727_133757 from "../../../assets/MARGARITA/20190727_133757.jpg";
import BAY_DSC4595_HDR_Edit from "../../../assets/BAY VIEW/_DSC4595-HDR-Edit.jpg";
import ias_1029pic7 from "../../../assets/IAS 6/1029pic7.jpg";
import HOUSE_INTERIOR_DESIGN_1 from "../../../assets/HOUSE INTERIOR DESIGN 1/K1.jpg";
import HOUSE_INTERIOR_DESIGN_2 from "../../../assets/HOUSE INTERIOR DESIGN 2/1.jpg";
import "./Residental.scss";

const Residential = () => {
  const history = useHistory();
  const photos = [
    // {
    //   title: "CONDO INTERIOR DESIGN",
    //   project: "jersey_street",
    //   name: one,
    // },
    {
      title: "HOUSE INTERIOR DESIGN",
      project: "MARGARITA",
      name: margarita_20190727_133757,
    },
    {
      title: "HOUSE RENOVATION",
      project: "IAS",
      name: ias_1029pic7,
    },
    // {
    //   title: "HOUSE RENOVATION",
    //   project: "IAS",
    //   name: ias_1029pic7,
    // },
    // {
    //   title: "APARTMENT RENOVATION",
    //   project: "BAY_VIEW",
    //   name: BAY_DSC4595_HDR_Edit,
    // },
    // {
    //   title: "HOUSE INTERIOR DESIGN",
    //   project: "HOUSE_INTERIOR_DESIGN_1",
    //   name: HOUSE_INTERIOR_DESIGN_1,
    // },
    // {
    //   title: "HOUSE INTERIOR DESIGN",
    //   project: "HOUSE_INTERIOR_DESIGN_2",
    //   name: HOUSE_INTERIOR_DESIGN_2,
    // },
  ];

  function pushToGellery(e) {
    history.push({
      pathname: "/gallery",
      state: e,
    });
  }
  return (
    <div className="Residental">
      <h1 >Under construction</h1>
    </div>
  )
  return (
    <div className="Residental">
      <Header />
      <div className="Residental__main">
        <div className="Residental__flex">
          {photos.map((res, index) => {
            return (
              <div className="Residental__photos_wrapper">
                <span className="Residental__title">{res.title}</span>
                <Image
                  click={(e) => pushToGellery(res)}
                  src={res.name}
                  key={index}
                />
              </div>
            );
          })}
        </div>
     
      </div>
      <Footer />
    </div>
  );


};



export default Residential;
