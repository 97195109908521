import React, { useState, useEffect } from "react";
import { styles } from "./styles";
import { withRouter } from "react-router";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useRef } from "react";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const classes = styles();
  const formRef = useRef();
  const [done, setDone] = useState(false);
  const initialValues = {
    user_name: "",
    user_subject: "",
    user_email: "",
    message: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const hundleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));

    if (
      formValues.user_name.length === 0 ||
      formValues.user_subject.length === 0 ||
      formValues.user_email.length === 0 ||
      formValues.message.length === 0
    ) {
      // console.log("is emtpy");
    } else {
      emailjs
        // .sendForm(
        //   "service_bw0dvhr",
        //   "template_rzw0pqi",
        //   formRef.current,
        //   "UtIRCdlnfYbkJsd2h"
        // )
        .sendForm(
          "service_00o73sc",
          "template_rzw0pqi",
          formRef.current,
          "u2ODkf7DdHqWCcRtw"
        )

        
        .then(
          (result) => {
            console.log(result.text);
            setDone(true);
          },
          (error) => {
            console.log(error.text);
          }
        );
      setIsSubmit(true);
      setFormValues({
        user_name: "",
        user_subject: "",
        user_email: "",
        message: "",
      });
    }
  };

  // useEffect(() => {
  //   console.log(formErrors);
  //   if(Object.keys(formErrors.length === 0 && isSubmit)){

  //     console.log("here");
  //   }else{
  //     console.log("something went wrong");
  //   }
  // },[formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.user_name) {
      errors.user_name = "Username is required!";
    }
    if (!values.user_email) {
      errors.user_email = "Email is required!";
    }
    if (!values.user_subject) {
      errors.user_subject = "Subject is required!";
    }
    if (!values.message) {
      errors.message = "Message is required!";
    }

    return errors;
  };

  return (
    <div>
      <Header />
      <main className={classes.mainScection}>
        <div className={classes.formWrapper}>
          <form ref={formRef} onSubmit={hundleSubmit}>
            <div>
              {Object.keys(formErrors).length === 0 && isSubmit ? (
                <div style={{ color: "green", fontWeight: "bold" }}>
                  Email has sent Successfully
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={classes.Contract_wrapper}>
              <div>book an appointment for interior consulting!!</div>
              <div>записаться на консультацию по интерьеру!!</div>
            </div>
            <input
              type="text"
              placeholder="Name/Имя"
              name="user_name"
              value={formValues.user_name}
              onChange={handleChange}
            />
            <p style={{ textAlign: "left", width: "100%", color: "red" }}>
              {formErrors.user_name}
            </p>
            <input
              type="text"
              placeholder="Subject/Предмет"
              name="user_subject"
              value={formValues.user_subject}
              onChange={handleChange}
            />
            <p style={{ textAlign: "left", width: "100%", color: "red" }}>
              {formErrors.user_subject}
            </p>
            <input
              type="text"
              placeholder="Email/Эл. адрес"
              name="user_email"
              value={formValues.user_email}
              onChange={handleChange}
            />
            <p style={{ textAlign: "left", width: "100%", color: "red" }}>
              {formErrors.user_email}
            </p>
            <textarea
              rows="5"
              placeholder="Message/Сообщение"
              name="message"
              value={formValues.message}
              onChange={handleChange}
            />
            <p style={{ textAlign: "left", width: "100%", color: "red" }}>
              {formErrors.message}
            </p>
            <button type="submit">Submit</button>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
