import React from "react";
import { useHistory } from "react-router-dom";
export function PageNotFound() {
  const history = useHistory();

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', width: '100vw', height: '100vh', color: 'black', backgroundColor: 'white', fontSize: '100px' }}>
      <h2 style={{ transform: 'translate(0,-200px)' }}>
        404 Page not found
      </h2>
      <button style={{ width: '200px', fontSize: '30px' }} onClick={() => history.push('/')}>Back</button>
    </div>
  );
}
