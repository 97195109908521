import React from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Home from "./Home.scss";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import Carousel from "react-bootstrap/Carousel";
import { useEffect, useState } from "react";
import * as serviceWorker from "../../serviceWorkerRegistration";
import highQualitySrc_1 from"../../assets/carousel/1.jpg"
import lowQualitySrc_1 from  "../../assets/carousel/small_images/1.jpg"
import highQualitySrc_2 from"../../assets/carousel/2.png"
import lowQualitySrc_2 from  "../../assets/carousel/small_images/2.jpg"
import highQualitySrc_3 from "../../assets/carousel/3.jpg"
import lowQualitySrc_3 from  "../../assets/carousel/small_images/3.jpg"
import highQualitySrc_4 from "../../assets/carousel/4.png"
import lowQualitySrc_4 from  "../../assets/carousel/small_images/4.jpg"
import highQualitySrc_5 from "../../assets/carousel/5.jpg"
import lowQualitySrc_5 from  "../../assets/carousel/small_images/5.jpg"

const App = () => {
  const greeting = "Hello Function Component!";
  const [src_1, setSrc_1] = React.useState(lowQualitySrc_1)
  const [src_2, setSrc_2] = React.useState(lowQualitySrc_2)
  const [src_3, setSrc_3] = React.useState(lowQualitySrc_3)
  const [src_4, setSrc_4] = React.useState(lowQualitySrc_4)
  const [src_5, setSrc_5] = React.useState(lowQualitySrc_5)

  useEffect(() => {
    setSrc_1(lowQualitySrc_1);
    const img = new Image();
    img.src = highQualitySrc_1;
    img.onload = () => {
      setSrc_1(highQualitySrc_1);

    };
  }, [lowQualitySrc_1, highQualitySrc_1]);

  useEffect(() => {
    setSrc_2(lowQualitySrc_2);
    const img = new Image();
    img.src = highQualitySrc_2;
    img.onload = () => {
      setSrc_2(highQualitySrc_2);

    };
  }, [lowQualitySrc_2, highQualitySrc_2]);

  useEffect(() => {
    setSrc_3(lowQualitySrc_3);
    const img = new Image();
    img.src = highQualitySrc_3;
    img.onload = () => {
      setSrc_3(highQualitySrc_3);

    };
  }, [lowQualitySrc_3, highQualitySrc_3]);


  useEffect(() => {
    setSrc_4(lowQualitySrc_4);
    const img = new Image();
    img.src = highQualitySrc_4;
    img.onload = () => {
      setSrc_4(highQualitySrc_4);

    };
  }, [lowQualitySrc_4, highQualitySrc_4]);

  useEffect(() => {
    setSrc_5(lowQualitySrc_5);
    const img = new Image();
    img.src = highQualitySrc_5;
    img.onload = () => {
      setSrc_5(highQualitySrc_5);
    };
  }, [lowQualitySrc_5, highQualitySrc_5]);
  return (
    <div className="siteWrapper home_component">


      <Header />

      <main className="home_component__container">
        <section className="page-section">
          <div className="section-background">
            <Carousel slide={true}>             
              <Carousel.Item interval={5000}>
                <img
                 loading="lazy"
                  src={src_1}
                  width="100%"
                  height="100%"
                  className="section-background__img "
                  id="section-background__img"
                  alt=""
                  style={{
                    filter: src_1 ===lowQualitySrc_1  ? "blur(20px)" : "none",
                    transition: src_1 ===lowQualitySrc_1 ? "none" : "filter 1.3s ease-out"
                  }}
                />
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <img
                //  loading="lazy"
                  className="section-background__img "
                  id="section-background__img"
                  // src={require("../../assets/carousel/2.png")}
                  src={src_2}
                  loading={"lazy"}
                  style={{
                    filter: src_2 ===lowQualitySrc_2  ? "blur(20px)" : "none",
                    transition: src_2 ===lowQualitySrc_2 ? "none" : "filter 1.3s ease-out"
                  }}
                  alt=""
                />
              </Carousel.Item>

              <Carousel.Item interval={5000}>
                <img
  
                  className="section-background__img "
                  id="section-background__img"
                  src={src_3}
                  loading={"lazy"}
                  style={{
                    filter: src_3 ===lowQualitySrc_3 ? "blur(20px)" : "none",
                    transition: src_3 ===lowQualitySrc_3 ? "none" : "filter 1.3s ease-out"
                  }}
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <img
        
                  className="section-background__img "
                  id="section-background__img"
                  src={src_4}
                  loading={"lazy"}
                  style={{
                    filter: src_4 ===lowQualitySrc_4 ? "blur(20px)" : "none",
                    transition: src_4 ===lowQualitySrc_4 ? "none" : "filter 1.3s ease-out"
                  }}
                  alt=""
                />
              </Carousel.Item>

              <Carousel.Item interval={5000}>
                <img
                 loading="lazy"
                  className="section-background__img "
                  id="section-background__img"
                  src={src_5}
                  // src={require("../../assets/carousel/5.jpg")}
                  style={{
                    filter: src_5 ===lowQualitySrc_5 ? "blur(20px)" : "none",
                    transition: src_5 ===lowQualitySrc_5 ? "none" : "filter 1.3s ease-out"
                  }}
                  alt=""
                />
              </Carousel.Item>
            </Carousel>
            {/* <img
             width="100%" height="100%"
              className="section-background__img" id="section-background__img"
              src={require("../../assets/primary_background.jpg")}
            /> */}
            {/* <Img lg={require("../../assets/primary_background.jpg")} 
            src="https://i.picsum.photos/id/1006/3000/2000.jpg?hmac=x83pQQ7LW1UTo8HxBcIWuRIVeN_uCg0cG6keXvNvM8g"
            height="700px" width="100%"
            /> */}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default App;
