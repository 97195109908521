import jersey_street1 from "../../assets/21 JERSEY STREET/1.jpg";
import jersey_street2 from "../../assets/21 JERSEY STREET/2.jpg";
import jersey_street3 from "../../assets/21 JERSEY STREET/3.jpg";
import jersey_street4 from "../../assets/21 JERSEY STREET/4.jpg";
import jersey_street5 from "../../assets/21 JERSEY STREET/5.jpg";

import jersey_street1_small from "../../assets/21 JERSEY STREET/small_images/1.jpg";
import jersey_street2_small from "../../assets/21 JERSEY STREET/small_images/2.jpg";
import jersey_street3_small from "../../assets/21 JERSEY STREET/small_images/3.jpg";
import jersey_street4_small from "../../assets/21 JERSEY STREET/small_images/4.jpg";
import jersey_street5_small from "../../assets/21 JERSEY STREET/small_images/5.jpg";

import margarita1 from "../../assets/MARGARITA/20190727_133720.jpg";
import margarita2 from "../../assets/MARGARITA/20190727_133740.jpg";
import margarita3 from "../../assets/MARGARITA/20190727_133757.jpg";

import ias1 from "../../assets/IAS 6/1029pic7.jpg";
import ias2 from "../../assets/IAS 6/1029pic9.jpg";
import ias3 from "../../assets/IAS 6/1029pic10.jpg";
import ias4 from "../../assets/IAS 6/1029pic11.jpg";
import ias5 from "../../assets/IAS 6/1029pic12.jpg";
import ias6 from "../../assets/IAS 6/1029pic13.jpg";
import ias7 from "../../assets/IAS 6/1029pic20.jpg";

import ias1_small from "../../assets/IAS 6/small_images/1029pic7.jpg";
import ias2_small from "../../assets/IAS 6/small_images/1029pic9.jpg";
import ias3_small from "../../assets/IAS 6/small_images/1029pic10.jpg";
import ias4_small from "../../assets/IAS 6/small_images/1029pic11.jpg";
import ias5_small from "../../assets/IAS 6/small_images/1029pic12.jpg";
import ias6_small from "../../assets/IAS 6/small_images/1029pic13.jpg";
import ias7_small from "../../assets/IAS 6/small_images/1029pic20.jpg";

import bay_view1 from "../../assets/BAY VIEW/_DSC4472-HDR-Edit.jpg";
import bay_view2 from "../../assets/BAY VIEW/_DSC4481-HDR-Edit.jpg";
import bay_view3 from "../../assets/BAY VIEW/_DSC4508-HDR-Edit.jpg";
import bay_view4 from "../../assets/BAY VIEW/_DSC4515-HDR-Edit.jpg";
import bay_view5 from "../../assets/BAY VIEW/_DSC4532-HDR-Edit.jpg";
import bay_view6 from "../../assets/BAY VIEW/_DSC4546-HDR-Edit.jpg";
import bay_view7 from "../../assets/BAY VIEW/_DSC4570-HDR-Edit.jpg";
import bay_view8 from "../../assets/BAY VIEW/_DSC4595-HDR-Edit.jpg";
import bay_view9 from "../../assets/BAY VIEW/_DSC4642-HDR-Edit.jpg";

import HOUSE_INTERIOR_DESIGN_1_1 from "../../assets/HOUSE INTERIOR DESIGN 1/K1.jpg";
import HOUSE_INTERIOR_DESIGN_1_2 from "../../assets/HOUSE INTERIOR DESIGN 1/LR1.jpg";
import HOUSE_INTERIOR_DESIGN_1_3 from "../../assets/HOUSE INTERIOR DESIGN 1/mb1.jpg";
import HOUSE_INTERIOR_DESIGN_1_4 from "../../assets/HOUSE INTERIOR DESIGN 1/WC.jpg";

import HOUSE_INTERIOR_DESIGN_1_1_small from "../../assets/HOUSE INTERIOR DESIGN 1/small_images/K1.jpg";
import HOUSE_INTERIOR_DESIGN_1_2_small from "../../assets/HOUSE INTERIOR DESIGN 1/small_images/LR1.jpg";
import HOUSE_INTERIOR_DESIGN_1_3_small from "../../assets/HOUSE INTERIOR DESIGN 1/small_images/mb1.jpg";
import HOUSE_INTERIOR_DESIGN_1_4_small from "../../assets/HOUSE INTERIOR DESIGN 1/small_images/WC.jpg";

import HOUSE_INTERIOR_DESIGN_2_1 from "../../assets/HOUSE INTERIOR DESIGN 2/1.jpg";
import HOUSE_INTERIOR_DESIGN_2_2 from "../../assets/HOUSE INTERIOR DESIGN 2/2.jpg";
import HOUSE_INTERIOR_DESIGN_2_3 from "../../assets/HOUSE INTERIOR DESIGN 2/3.jpg";
import HOUSE_INTERIOR_DESIGN_2_4 from "../../assets/HOUSE INTERIOR DESIGN 2/4.jpg";
import HOUSE_INTERIOR_DESIGN_2_5 from "../../assets/HOUSE INTERIOR DESIGN 2/5.jpg";
import HOUSE_INTERIOR_DESIGN_2_6 from "../../assets/HOUSE INTERIOR DESIGN 2/6.jpg";
import HOUSE_INTERIOR_DESIGN_2_7 from "../../assets/HOUSE INTERIOR DESIGN 2/7.jpg";
import HOUSE_INTERIOR_DESIGN_2_8 from "../../assets/HOUSE INTERIOR DESIGN 2/8.jpg";
import HOUSE_INTERIOR_DESIGN_2_9 from "../../assets/HOUSE INTERIOR DESIGN 2/9.jpg";

import HOUSE_INTERIOR_DESIGN_2_1_small from "../../assets/HOUSE INTERIOR DESIGN 2/small_images/1.jpg";
import HOUSE_INTERIOR_DESIGN_2_2_small from "../../assets/HOUSE INTERIOR DESIGN 2/small_images/2.jpg";
import HOUSE_INTERIOR_DESIGN_2_3_small from "../../assets/HOUSE INTERIOR DESIGN 2/small_images/3.jpg";
import HOUSE_INTERIOR_DESIGN_2_4_small from "../../assets/HOUSE INTERIOR DESIGN 2/small_images/4.jpg";
import HOUSE_INTERIOR_DESIGN_2_5_small from "../../assets/HOUSE INTERIOR DESIGN 2/small_images/5.jpg";
import HOUSE_INTERIOR_DESIGN_2_6_small from "../../assets/HOUSE INTERIOR DESIGN 2/small_images/6.jpg";
import HOUSE_INTERIOR_DESIGN_2_7_small from "../../assets/HOUSE INTERIOR DESIGN 2/small_images/7.jpg";
import HOUSE_INTERIOR_DESIGN_2_8_small from "../../assets/HOUSE INTERIOR DESIGN 2/small_images/8.jpg";
import HOUSE_INTERIOR_DESIGN_2_9_small from "../../assets/HOUSE INTERIOR DESIGN 2/small_images/9.jpg";

import HOUSE_INTERIOR_DESIGN_3_1 from "../../assets/HOUSE INTERIOR DESIGN 3/1.png";
import HOUSE_INTERIOR_DESIGN_3_2 from "../../assets/HOUSE INTERIOR DESIGN 3/2.png";
import HOUSE_INTERIOR_DESIGN_3_2_1 from "../../assets/HOUSE INTERIOR DESIGN 3/2_1.png";
import HOUSE_INTERIOR_DESIGN_3_3 from "../../assets/HOUSE INTERIOR DESIGN 3/3.png";
import HOUSE_INTERIOR_DESIGN_3_3_1 from "../../assets/HOUSE INTERIOR DESIGN 3/3_1.png";
import HOUSE_INTERIOR_DESIGN_3_4 from "../../assets/HOUSE INTERIOR DESIGN 3/4.png";

import HOUSE_INTERIOR_DESIGN_3_1_small from "../../assets/HOUSE INTERIOR DESIGN 3/small_images/1.png";
import HOUSE_INTERIOR_DESIGN_3_2_small from "../../assets/HOUSE INTERIOR DESIGN 3/small_images/2.png";
import HOUSE_INTERIOR_DESIGN_3_2_1_small from "../../assets/HOUSE INTERIOR DESIGN 3/small_images/2_1.png";
import HOUSE_INTERIOR_DESIGN_3_3_small from "../../assets/HOUSE INTERIOR DESIGN 3/small_images/3.png";
import HOUSE_INTERIOR_DESIGN_3_3_1_small from "../../assets/HOUSE INTERIOR DESIGN 3/small_images/3_1.png";
import HOUSE_INTERIOR_DESIGN_3_4_small from "../../assets/HOUSE INTERIOR DESIGN 3/small_images/4.png";

import HOUSE_INTERIOR_DESIGN_5_1 from "../../assets/HOUSE INTERIOR DESIGN 5/1.png";
import HOUSE_INTERIOR_DESIGN_5_2 from "../../assets/HOUSE INTERIOR DESIGN 5/2.png";
import HOUSE_INTERIOR_DESIGN_5_3 from "../../assets/HOUSE INTERIOR DESIGN 5/3.png";
import HOUSE_INTERIOR_DESIGN_5_4 from "../../assets/HOUSE INTERIOR DESIGN 5/4.png";
import HOUSE_INTERIOR_DESIGN_5_5 from "../../assets/HOUSE INTERIOR DESIGN 5/5.png";

import HOUSE_INTERIOR_DESIGN_5_1_small from "../../assets/HOUSE INTERIOR DESIGN 5/small_images/1.png";
import HOUSE_INTERIOR_DESIGN_5_2_small from "../../assets/HOUSE INTERIOR DESIGN 5/small_images/2.png";
import HOUSE_INTERIOR_DESIGN_5_3_small from "../../assets/HOUSE INTERIOR DESIGN 5/small_images/3.png";
import HOUSE_INTERIOR_DESIGN_5_4_small from "../../assets/HOUSE INTERIOR DESIGN 5/small_images/4.png";
import HOUSE_INTERIOR_DESIGN_5_5_small from "../../assets/HOUSE INTERIOR DESIGN 5/small_images/5.png";

const HOUSE_INTERIOR_DESIGN_5 = {
  title: "HOUSE INTERIOR DESIGN",
  description:
    "Residential Interior Design with 3D Visuals according to client’s taste and needs. ",
  location: "LARNACA, CY",

    photos_small: [
      { name: HOUSE_INTERIOR_DESIGN_5_1_small, class: "gallery-container h-2 w-3 " },
      { name: HOUSE_INTERIOR_DESIGN_5_2_small, class: "gallery-container h-4 w-1 " },
      { name: HOUSE_INTERIOR_DESIGN_5_3_small, class: "gallery-container h-2 w-3 " },
      { name: HOUSE_INTERIOR_DESIGN_5_4_small, class: "gallery-container h-2 w-3 " },
      { name: HOUSE_INTERIOR_DESIGN_5_5_small, class: "gallery-container h-2 w-1 " }
    ],
    photos: [
      { name: HOUSE_INTERIOR_DESIGN_5_1, lowImage:HOUSE_INTERIOR_DESIGN_5_1_small ,class: "gallery-container h-2 w-3 " },
      { name: HOUSE_INTERIOR_DESIGN_5_2,lowImage:HOUSE_INTERIOR_DESIGN_5_2_small,  class: "gallery-container h-4 w-1 " },
      { name: HOUSE_INTERIOR_DESIGN_5_3,lowImage:HOUSE_INTERIOR_DESIGN_5_3_small,  class: "gallery-container h-2 w-3 " },
      { name: HOUSE_INTERIOR_DESIGN_5_4,lowImage:HOUSE_INTERIOR_DESIGN_5_4_small , class: "gallery-container h-2 w-3 " },
      { name: HOUSE_INTERIOR_DESIGN_5_5, lowImage:HOUSE_INTERIOR_DESIGN_5_5_small , class: "gallery-container h-2 w-1 " }
    ]


};

const HOUSE_INTERIOR_DESIGN_3 = {
  title: "HOUSE INTERIOR DESIGN",
  description:
    "Residential Interior Design with 3D Visuals according to client’s taste and needs. ",
  location: "LOCATION: NICOSIA, CY",
  photos_small: [
    { name: HOUSE_INTERIOR_DESIGN_3_1_small, class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_3_2_small, class: "gallery-container h-4 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_3_2_1_small, class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_3_3_small, class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_3_3_1_small, class: "gallery-container h-2 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_3_4_small, class: "gallery-container h-2 w-4 " },
  ],
  photos: [
    { name: HOUSE_INTERIOR_DESIGN_3_1,lowImage:HOUSE_INTERIOR_DESIGN_3_1_small , class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_3_2,lowImage:HOUSE_INTERIOR_DESIGN_3_2_small , class: "gallery-container h-4 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_3_2_1, lowImage:HOUSE_INTERIOR_DESIGN_3_2_1_small ,class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_3_3,lowImage:HOUSE_INTERIOR_DESIGN_3_3_small , class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_3_3_1,lowImage:HOUSE_INTERIOR_DESIGN_3_3_1_small , class: "gallery-container h-2 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_3_4, lowImage:HOUSE_INTERIOR_DESIGN_3_4_small ,class: "gallery-container h-2 w-4 " },
  ],
};

const HOUSE_INTERIOR_DESIGN_2 = {
  title: "HOUSE INTERIOR DESIGN",
  description:
    "Residential Interior Design with 3D Visuals according to client’s taste and needs.",
  location: "LOCATION: NICOSIA, CY",
  photos_small: [
    { name: HOUSE_INTERIOR_DESIGN_2_1_small, class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_2_2_small, class: "gallery-container h-6 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_2_3_small, class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_2_4_small, class: "gallery-container h-4 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_2_5_small, class: "gallery-container h-2 w-4 " },
    { name: HOUSE_INTERIOR_DESIGN_2_6_small, class: "gallery-container h-2 w-2 " },
    { name: HOUSE_INTERIOR_DESIGN_2_7_small, class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_2_8_small, class: "gallery-container h-2 w-2 " },
    { name: HOUSE_INTERIOR_DESIGN_2_9_small, class: "gallery-container h-2 w-2 " },
  ],
  photos: [
    { name: HOUSE_INTERIOR_DESIGN_2_1,lowImage:HOUSE_INTERIOR_DESIGN_2_1_small , class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_2_2,lowImage:HOUSE_INTERIOR_DESIGN_2_2_small , class: "gallery-container h-6 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_2_3, lowImage:HOUSE_INTERIOR_DESIGN_2_3_small ,class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_2_4,lowImage:HOUSE_INTERIOR_DESIGN_2_4_small , class: "gallery-container h-4 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_2_5,lowImage:HOUSE_INTERIOR_DESIGN_2_5_small , class: "gallery-container h-2 w-4 " },
    { name: HOUSE_INTERIOR_DESIGN_2_6,lowImage:HOUSE_INTERIOR_DESIGN_2_6_small , class: "gallery-container h-2 w-2 " },
    { name: HOUSE_INTERIOR_DESIGN_2_7,lowImage:HOUSE_INTERIOR_DESIGN_2_7_small , class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_2_8,lowImage:HOUSE_INTERIOR_DESIGN_2_8_small , class: "gallery-container h-2 w-2 " },
    { name: HOUSE_INTERIOR_DESIGN_2_9,lowImage:HOUSE_INTERIOR_DESIGN_2_9_small , class: "gallery-container h-2 w-2 " },
  ],
};

const HOUSE_INTERIOR_DESIGN_1 = {
  title: "HOUSE INTERIOR DESIGN",
  description:
    "Residential Interior Design with 3D Visuals according to client’s taste and needs.",
  location: "LOCATION: NICOSIA, CY",
  photos_small: [
    { name: HOUSE_INTERIOR_DESIGN_1_1_small, class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_1_2_small, class: "gallery-container h-4 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_1_3_small, class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_1_4_small, class: "gallery-container h-2 w-4 " },
  ],
  photos: [
    { name: HOUSE_INTERIOR_DESIGN_1_1,lowImage:HOUSE_INTERIOR_DESIGN_1_1_small , class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_1_2, lowImage:HOUSE_INTERIOR_DESIGN_1_2_small ,class: "gallery-container h-4 w-1 " },
    { name: HOUSE_INTERIOR_DESIGN_1_3,lowImage:HOUSE_INTERIOR_DESIGN_1_3_small , class: "gallery-container h-2 w-3 " },
    { name: HOUSE_INTERIOR_DESIGN_1_4,lowImage:HOUSE_INTERIOR_DESIGN_1_4_small , class: "gallery-container h-2 w-4 " },
  ],
};

const jersey_street = {
  title: "CONDO INTERIOR DESIGN",
  description:
    "Residential Interior Design with 3D Visuals according to client’s taste and needs.",
  location: "LOCATION: LONDON, UK",
  photos_small: [
    { name: jersey_street1_small, class: "gallery-container h-2 w-3 " },
    { name: jersey_street2_small, class: "gallery-container h-4 w-1 " },
    { name: jersey_street3_small, class: "gallery-container h-2 w-2 " },
    { name: jersey_street4_small, class: "gallery-container h-2 w-1 " },
    { name: jersey_street5_small, class: "gallery-container h-2 w-4 " },
  ],
  photos: [
    { name: jersey_street1, lowImage:jersey_street1_small ,class: "gallery-container h-2 w-3 " },
    { name: jersey_street2, lowImage:jersey_street2_small ,class: "gallery-container h-4 w-1 " },
    { name: jersey_street3,lowImage:jersey_street3_small , class: "gallery-container h-2 w-2 " },
    { name: jersey_street4, lowImage:jersey_street4_small ,class: "gallery-container h-2 w-1 " },
    { name: jersey_street5, lowImage:jersey_street5_small ,class: "gallery-container h-2 w-4 " },
  ],
};
const margarita = {
  title:
    "Residential Interior Design and finishes selection according to client’s taste and needs.",
  photos: [
    { name: margarita3, class: "gallery-container h-2 w-2 " },
    { name: margarita2, class: "gallery-container h-2 w-2 " },
    { name: margarita1, class: "gallery-container h-4 w-1 " },
  ],
};

const ias = {
    title: "HOUSE RENOVATION – COOPORATION WITH A CONSTRUCTION & DEVELOPMENT COMPANY",
    description:
    "Throughout the interior and exterior renovation of this old-fashion house I have transformed it into a new luxurious and modern space. The classic floor and wall tiles have been replaced by exquisite panels of marble."
    + "I have chosen glossy finishes for kitchen and wardrobes doors to provide more lighting and reflection. The bathrooms are designed with a practical way according to the client’s needs. ",
    location: "LOCATION: YERMASOYIA – LIMASSOL, CY",
    photos_small: [
      { name: ias1_small,  class: "gallery-container h-2 w-2 " },
      { name: ias2_small,  class: "gallery-container h-2 w-2 " },
      { name: ias3_small,  class: "gallery-container h-3 w-3 " },
      { name: ias4_small,  class: "gallery-container h-2 w-2 " },
      { name: ias5_small,  class: "gallery-container h-3 w-1 " },
      { name: ias6_small,  class: "gallery-container h-2 w-1 " },
      { name: ias7_small,  class: "gallery-container h-2 w-1 " },
    ],
  photos: [
    { name: ias1,lowImage:ias1_small , class: "gallery-container h-2 w-2 " },
    { name: ias2,lowImage:ias2_small , class: "gallery-container h-2 w-2 " },
    { name: ias3,lowImage:ias3_small , class: "gallery-container h-3 w-3 " },
    { name: ias4, lowImage:ias4_small ,class: "gallery-container h-2 w-2 " },
    { name: ias5,lowImage:ias5_small , class: "gallery-container h-3 w-1 " },
    { name: ias6, lowImage:ias6_small ,class: "gallery-container h-2 w-1 " },
    { name: ias7,lowImage:ias7_small , class: "gallery-container h-2 w-1 " },
  ],
};

const bay_view = {
  title:
    "Throughout the renovation of this old-fashion apartment I have transformed it into a new luxurious and modern new space. The classic floor and wall tiles have been replaced by exquisite panels of marble or solid wooden parquet. I have chosen glossy finishes for kitchen and wardrobes doors to provide more lighting and reflection. The bathrooms are designed with a special way according to my client’s taste and needs.  ",
  photos: [
    bay_view1,
    bay_view2,
    bay_view3,
    bay_view4,
    bay_view5,
    bay_view6,
    bay_view7,
    bay_view8,
    bay_view9,
  ],
};
const jersey_street22 = jersey_street1;

export {
  jersey_street,
  margarita,
  ias,
  bay_view,
  jersey_street22,
  HOUSE_INTERIOR_DESIGN_1,
  HOUSE_INTERIOR_DESIGN_2,
  HOUSE_INTERIOR_DESIGN_3,
  HOUSE_INTERIOR_DESIGN_5
};
