import React, { Component } from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { logDOM } from "@testing-library/react";
import Images from "../img/Image";
const CustomHttp = () => {
  const [images, setImage] = useState([]);
  useEffect(() => {
    axios.get(`http://127.0.0.1:5000/images`).then((res) => {
      let data = res;
      setImage(data.data.images);
    });
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {images.map((item, index) => (
       
         <Images
         className="image"
         click={null}
         highQualitySrc={item}
         lowQualitySrc={''}
       />

        // item ? <img style={{ width: "500px", height: "500px" }} src={item} /> : ""
      ))}
    </div>
  );
};
export default CustomHttp;
