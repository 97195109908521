
import { createUseStyles } from "react-jss";


const XSmallDevices = 575.98
const SmallDevices = 767.98
const MediumDevices = 990

  export const styles = createUseStyles({

    Contract_wrapper:{
      fontSize:"1.5rem",
      color: "grey"
    },
    
    bg: {
      color:"green"
    },
    mainScection: {
      height:'calc(100vh - 298px)',
      // height:'100vh',
      backgroundColor:'var(--contact-background)',
      
    },
    formWrapper: {
       height:'100%',
       width:'50%',
       marginRight:'auto',
       marginLeft:'auto',
      '& form':{
       
        width:'100%',
        height: '100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:'100%'
        
      },

      '& input':{
        width: '100%',
        height: '50px',
        margin: '10px 0',
        fontSize:'14px',
        border:'none',
        borderBottom:'1px solid black',
        backgroundColor:'transparent',
        'margin':'10px 0px',
        
        '&:focus':{
          outline:'none',
        }
      },
      
      '& textarea':{
        width: '100%',
        backgroundColor:'transparent',
        margin: '10px 0',
        fontSize:'14px',
        padding:5,
        '&:focus':{
          outline:'none',
        }
      },
      '& button':{
        border:'none',
        padding:'25px 35px',
        color:'black',
        border:'1px solid black',
        fontWeight:'500',
        cursor:'pointer',
        marginRight:'auto',
        backgroundColor:'transparent',
        '&:hover':{
          backgroundColor:'black',
          color:"white",
          fontWeight:300
        }
      }
     
     
    },

    
    [`@media (max-width: ${MediumDevices}px)`]: 
    {
      Contract_wrapper:{
        fontSize:"1.3rem",
        color: "grey"
      },
    },

    [`@media (max-width: ${SmallDevices}px)`]: {

    Contract_wrapper:{
      fontSize:"1.2rem",
      color: "grey"
    },
      formWrapper: {
        height:'100%',
        width:'70%',
        marginRight:'auto',
        marginLeft:'auto',
      }
    },
    [`@media (max-width: ${XSmallDevices}px)`]: {
      Contract_wrapper:{
        fontSize:"1rem",
        color: "grey"
      },
      formWrapper: {
        padding:'10px',
        height:'100%',
        width:'100%',
      }
    },
    
     
  })
